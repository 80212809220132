import { useState, useEffect, useRef, useContext } from "react";
import {
  Typography,
  Layout,
  Checkbox,
  DatePicker,
  Select,
  Spin,
  Descriptions,
  Alert,
  Tooltip,
} from "antd";
import { Button, message, Steps, Radio, Form, Input, Col, Row } from "antd";
import {
  UserOutlined,
  CheckCircleFilled,
  ClockCircleFilled,
  CloseCircleFilled,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  getOnboarding,
  updateOnboarding,
  updateradid,
  updatefailradid,
  getStates,
  update_profile,
  getQuestions,
} from "./api.js";
import bcgimg from "./asset/bcg.svg";
import bcgfail from "./asset/bcg-fail.svg";
import SignatureCanvas from "react-signature-canvas";
import { GlobalContext } from "./GlobalContext.js";
import TopHeader from "./Header.js";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import FileUpload from "./FileUpload.js";
import FileUpload2 from "./FileUpload2.js";
import FileUploadDoc from "./FileUploadDoc.js";
import FileGovUpload from "./FileGovUpload.js";

const { Content, Footer } = Layout;
const { Paragraph, Title } = Typography;
// const { Option } = Select;

const steps = [
  {
    title: "Personal info",
    icon: <UserOutlined />,
  },
  {
    title: "Address info",
    icon: <UserOutlined />,
  },
  {
    title: "RAD ID",
    icon: <UserOutlined />,
  },
  {
    title: "Additional info",
    icon: <UserOutlined />,
  },
  {
    title: "FCRA Summary of Rights",
    icon: <UserOutlined />,
  },
  {
    title: "Terms&Condi.",
    icon: <UserOutlined />,
  },
  {
    title: "Finish",
    icon: <UserOutlined />,
  },
];

export default function OnBoarding() {
  const { user, setUser } = useContext(GlobalContext);
  const [userData, setUserData] = useState();
  const [form] = Form.useForm();
  const [dataloading, setdataloading] = useState(false);
  const [loading, setloading] = useState(false);
  const [onboardingdata, setonboardingdata] = useState("");
  const [current, setCurrent] = useState(3);
  const [radvalue, radsetValue] = useState(true);
  const [signature, setSignature] = useState(null);
  const [fcrasignature, setfcraSignature] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [fileList1, setFileList1] = useState([]);
  const [fileList2, setFileList2] = useState([]);
  const [fileList3, setFileList3] = useState([]);
  const [fileList4, setFileList4] = useState([]);
  const [fileList5, setFileList5] = useState([]);
  const [fileList6, setFileList6] = useState([]);
  const [photourl, setphotourl] = useState("");
  const [photourl2, setphotourl2] = useState("");
  const [governmenturl, setgovernmenturl] = useState("");
  const [retailerurl, setretailerurl] = useState("");
  const [supporturl, setsupporturl] = useState("");
  const [retailerurl1, setretailerurl1] = useState("");
  const [supporturl1, setsupporturl1] = useState("");
  const [radid, setradid] = useState("");
  const [isRADIDError, setRADIDError] = useState(false);
  const [shortstate, setshortstate] = useState([]);
  const [consent, setConsent] = useState(false);
  const [terms, setTerms] = useState(false);
  const [source, setSource] = useState("");
  const [question, setQuestions] = useState([]);
  const [selectedValues, setSelectedValues] = useState({
    security_question1: null,
    security_question2: null,
    security_question3: null,
  });
  const [questionTypeAndLimit, setQuestionTypeAndLimit] = useState({});
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  // const [radissue, setradissue] = useState(false);

  const sigRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    
    setphotourl(onboardingdata?.photo_copy_url ?? "");
    setphotourl2(onboardingdata?.photo_copy_2_url ?? "");
    setgovernmenturl(onboardingdata?.government_document_url ?? "");
    setretailerurl(onboardingdata?.retailer_agent_agreement_url ?? "");
    setsupporturl(onboardingdata?.support_document_url ?? "");
  }, [onboardingdata]);

  useEffect(() => {
    if (user) {
      getStates(user?.token).then((res) => {
        setshortstate(res?.data);
      });
      getQuestions(user?.token).then((res) => {
        setQuestions(res?.data);
      });
      setdataloading(true);
      getOnboarding(user?.candidate_id, user?.token)
        .then((res) => {
          console.log(user);
          console.log(res);
          // setradissue(Array.isArray(res?.data?.items?.rad_pii_issues) && res?.data?.items?.rad_pii_issues.includes(5));
          radsetValue(res?.data?.items?.rad_check ?? 0);
          setonboardingdata(res?.data?.items);
          setCurrent(res?.data?.items?.completed_step + 1);
          setSource(res?.data?.items?.onboarding_source);
          setTerms(res.data.items?.disclaimer_check === 0 ? false : true);
          setdataloading(false);

        })
        .catch((err) => {
          console.log(err);
          // console.log(err.response.status);
          setdataloading(false);
          if (err.response.status === 401) {
            setUser("");
            navigate("/");
          }
        });
    } else {
      setUser("");
      navigate("/");
    }
  }, [user]);

  const getdata = () => {
    setdataloading(true);
    getOnboarding(user?.candidate_id, user?.token)
      .then((res) => {
        console.log(res.data.items);
        // setradissue(Array.isArray(res?.data?.items?.rad_pii_issues) && res?.data?.items?.rad_pii_issues.includes(5));
        setConsent(res.data.items?.disclaimer_check === 0 ? false : true);
        setTerms(res.data.items?.disclaimer_check === 0 ? false : true);
        setUserData(res?.data?.items);
        radsetValue(res?.data?.items?.rad_check ?? 0);
        setonboardingdata(res?.data?.items);
        setdataloading(false);
        setSource(res?.data?.items?.onboarding_source);
      })
      .catch((err) => {
        console.log(err);
        setdataloading(false);
        message.error(err?.response?.data?.error ?? "Something went wrong!");
      });
  };

  const profilesubmitData = (values) => {
    setloading(true);
    const formdata = new FormData();
    formdata.append("city", values?.city);
    formdata.append("dob", values?.dob);
    formdata.append("email", values?.email);
    formdata.append("first_name", values?.first_name);
    formdata.append("last_name", values?.last_name);
    formdata.append("middle_name", values?.middle_name ?? "");
    formdata.append("mobile_number", values?.mobile_number);
    formdata.append("state", values?.state);
    formdata.append("street_address1", values?.street_address1);
    formdata.append("street_address2", values?.street_address2 ?? "");
    formdata.append("zip", values?.zip);
    formdata.append("rad_id", values?.rad_id);
    formdata.append("shirt_size", values?.shirt_size);
    formdata.append("ssn_number", values?.ssn_number);
    formdata.append("gender_id", values?.gender_id);

    fileList.forEach((element) => {
      formdata.append("government_document", element);
    });
    fileList1.forEach((element) => {
      formdata.append("photo_copy", element);
    });
    fileList6.forEach((element) => {
      formdata.append("photo_copy_2", element);
    });
    fileList2.forEach((element) => {
      formdata.append("retailer_agent_agreement", element);
    });
    fileList3.forEach((element) => {
      formdata.append("support_document", element);
    });
    update_profile(user?.candidate_id, formdata, user?.token)
      .then((res) => {
        setloading(false);
        getdata();
      })
      .catch((err) => {
        setloading(false);
        message.error(err?.response?.data?.error ?? "Something went wrong!");
      });
  };
  const submitData = (values) => {
    setloading(true);
    updateOnboarding(
      user?.candidate_id,
      { ...values, _method: "PUT", step: current },
      user?.token
    )
      .then((res) => {
        setloading(false);
        setCurrent(current + 1);
        getdata();
      })
      .catch((err) => {
        setloading(false);
        message.error(err?.response?.data?.error ?? "Something went wrong!");
      });
  };
  // step 1 submit
  const UpdateProfileFinish = (values) => {
    profilesubmitData({
      ...values,
      dob: moment(values?.dob).format("MM-DD-YYYY"),
    });
  };
  // step 1 submit
  const onFinish = (values) => {
    values.disclaimer_check = values.disclaimer_check ? 1 : 0;
    submitData({ ...values, dob: moment(values?.dob).format("MM-DD-YYYY") });
  };
  // step 2 submit
  const onFinishAddress = (values) => {
    submitData({ ...values });
  };
  // step 3 submit
  const onFinishAdditional = (values) => {
    values.rad_id = values?.rad_id ?? "";
    submitData({ ...values });
  };
  // step 4 submit
  const onFinishProof = (values) => {
    if (fileList?.length > 0 || fileList1?.length > 0 || fileList6?.length > 0) {
      const formdata = new FormData();
      formdata.append("step", "4");
      formdata.append("_method", "PUT");
      fileList.forEach((element) => {
        formdata.append("government_document", element);
      });
      fileList6.forEach((element) => {
        formdata.append("photo_copy_2", element);
      });
      fileList1.forEach((element) => {
        formdata.append("photo_copy", element);
      });
      fileList2.forEach((element) => {
        formdata.append("retailer_agent_agreement", element);
      });
      fileList3.forEach((element) => {
        formdata.append("support_document", element);
      });
      setloading(true);
      updateOnboarding(user?.candidate_id, formdata, user?.token)
        .then((res) => {
          setloading(false);
          setCurrent(current + 1);
          setFileList([]);
          setFileList1([]);
          getdata();
        })
        .catch((err) => {
          console.log(err);
          setloading(false);
          message.error(err?.response?.data?.error ?? "Something went wrong!");
        });
    } else {
      setCurrent(current + 1);
    }
  };
  // step 5 submit
  const onFinishTerms = (values) => {
    console.log(values);

    const formdata = new FormData();
    formdata.append("step", "6");
    formdata.append("_method", "PUT");
    formdata.append("signature", signature);
    formdata.append("terms_check", values?.terms_check ? 1 : 0);
    formdata.append(
      "bgc_file_access_check",
      values?.bgc_file_access_check ? 1 : 0
    );
    setloading(true);
    updateOnboarding(user?.candidate_id, formdata, user?.token)
      .then((res) => {
        setloading(false);
        setCurrent(current + 1);
        getdata();
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
        message.error(err.response.data.error);
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const onFinishTerms1 = (values) => {
    const formdata = new FormData();
    formdata.append("step", "5");
    formdata.append("_method", "PUT");
    formdata.append("fcra_signature", fcrasignature);
    formdata.append("fcra_terms_check", values?.fcra_terms_check ? 1 : 0);
    setloading(true);
    updateOnboarding(user?.candidate_id, formdata, user?.token)
      .then((res) => {
        setloading(false);
        setCurrent(current + 1);
        getdata();
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
        message.error(err.response.data.error);
      });
    // else {
    //   setCurrent(current + 1);
    // }
  };
  const onFinishFailed1 = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const prev = () => {
    setCurrent(current - 1);
    getdata();
  };
  const items = steps.map((item, i) => ({
    key: i + 1,
    title: item.title,
  }));

  const onChangeRAD = (e) => {
    radsetValue(e.target.value);
  };
  const consentHandler = (e) => {
    setConsent(e.target.checked);
  };
  const handleSignatureEnd = () => {
    var blobBin = atob(sigRef.current.toDataURL().split(",")[1]);
    var array = [];
    for (var i = 0; i < blobBin.length; i++) {
      array.push(blobBin.charCodeAt(i));
    }
    var file = new Blob([new Uint8Array(array)], { type: "image/png" });
    setSignature(file);
  };
  const clearSignature = () => {
    sigRef.current.clear();
    setSignature(null);
  };

  const handlefcraSignatureEnd = () => {
    var blobBin = atob(sigRef.current.toDataURL().split(",")[1]);
    var array = [];
    for (var i = 0; i < blobBin.length; i++) {
      array.push(blobBin.charCodeAt(i));
    }
    var file = new Blob([new Uint8Array(array)], { type: "image/png" });
    setfcraSignature(file);
  };
  const clearfcraSignature = () => {
    sigRef.current.clear();
    setfcraSignature(null);
  };
  useEffect(() => {
    form.setFieldsValue({
      ...onboardingdata,
      dob: onboardingdata?.dob
        ? moment(onboardingdata?.dob ?? "", "MM-DD-YYYY")
        : null,
      is_rad: !!onboardingdata?.rad_check,
    });
  }, [form, onboardingdata, current]);

  // console.log(usstate);
  const radidupdate = () => {
    setdataloading(true);
    var obj_arr = {
      rad_id: radid,
      candidate_id: user?.candidate_id,
    };
    console.log("radif", obj_arr);
    updateradid(user?.candidate_id, obj_arr, user?.token)
      .then((res) => {
        console.log(res);
        message.success(res?.data?.message);
        getdata();
      })
      .catch((err) => {
        console.log(err);
        setdataloading(false);
        message.error(err?.response?.data?.error ?? "Something went wrong!");
      });
  };

  const radfailupdate = (values) => {
    setdataloading(true);
    const formdata = new FormData();

    fileList4.forEach((element) => {
      formdata.append("retailer_agent_agreement", element);
    });
    fileList5.forEach((element) => {
      formdata.append("support_document", element);
    });
    formdata.append("rad_id", values.update_rad_id);
    formdata.append("candidate_id", user?.candidate_id);

    updatefailradid(formdata, user?.token)
      .then((res) => {
        console.log(res);
        message.success(res?.data?.message);
        getdata();
      })
      .catch((err) => {
        console.log(err);
        setdataloading(false);
        message.error(err?.response?.data?.error ?? "Something went wrong!");
      });
  };

  const renderFCRAAgreement = () => {
    switch (true) {
      // Los Angeles
      case userData?.bgc_form_state === 5:
        return (
          <Row gutter={24}>
            <Col xs={24} lg={24} xl={24}>
              <Typography>
                <Paragraph>
                  <a
                    href="https://wieserp.s3.amazonaws.com/Victig/California/Los+Angeles/FCRA+Summary+of+Rights+2023.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Click here to view FCRA Summary of Rights 2024{" "}
                  </a>
                </Paragraph>
                {/* <Col xs={24} lg={24} xl={24}> */}
                <Form.Item
                  name="fcra_terms_check"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(
                            new Error("Should accept agreement")
                          ),
                    },
                  ]}
                >
                  <Checkbox>
                    Please check this box if you have read the FCRA Summary of
                    Rights 2023{" "}
                  </Checkbox>
                </Form.Item>
                {/* </Col> */}
                <Paragraph>
                  <a
                    href="https://wieserp.s3.amazonaws.com/Victig/California/Los+Angeles/LA+Fair+Chance+Initiative+For+HiringNotice.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Click here to view LA Fair Chance Initiative For Hiring
                    Notice{" "}
                  </a>
                </Paragraph>
                {/* <Col xs={24} lg={24} xl={24}> */}
                <Form.Item
                  name="LA_Hiring_Notice"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(
                            new Error("Should accept agreement")
                          ),
                    },
                  ]}
                >
                  <Checkbox checked={onboardingdata?.fcra_signature_url}>
                    Please check this box if you have read the LA Fair Chance
                    Initiative For Hiring Notice{" "}
                  </Checkbox>
                </Form.Item>
                {/* </Col> */}
                <Paragraph>
                  <a
                    href="https://wieserp.s3.amazonaws.com/Victig/California/Los+Angeles/Excess+Telecom+NOTICE+REGARDING+BACKGROUNDCHECKS+PER+CALIFORNIA+LAW.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Click here to view NOTICE REGARDING BACKGROUND CHECKS PER
                    CALIFORNIA LAW{" "}
                  </a>
                </Paragraph>
                {/* <Col xs={24} lg={24} xl={24}> */}
                <Form.Item
                  name="BGC_CA_LAW"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(
                            new Error("Should accept agreement")
                          ),
                    },
                  ]}
                >
                  <Checkbox checked={onboardingdata?.fcra_signature_url}>
                    Please check this box if you would like to receive a copy of
                    an investigative consumer report at no charge if one is
                    obtained by the Company whenever you have a right to receive
                    such a copy under California law.{" "}
                  </Checkbox>
                </Form.Item>
                {/* </Col> */}
              </Typography>
            </Col>
            <Col xs={24} lg={24} xl={24}>
              <h5 className="mb-0">
                CALIFORNIA DISCLOSURE REGARDING “INVESTIGATIVE CONSUMER REPORT”
                BACKGROUND INVESTIGATION
              </h5>
              <hr />
              <Typography>
                <Paragraph>
                  <strong>Excess Telecom</strong> (the “Company”) may request an
                  investigative consumer report about you from a third party
                  consumer reporting agency, in connection with your employment
                  or application for employment (including volunteer
                  assignment(s), as applicable) and throughout your employment
                  if you are hired or retained, as allowed by law. An
                  “investigative consumer report” is a background report that
                  includes information from personal interviews (except in
                  California, where that term includes background reports with
                  or without information obtained from personal interviews). The
                  most common form of an investigative consumer report in
                  connection with your employment is a reference check through
                  personal interviews with sources such as your former employers
                  and associates, and other information sources. The
                  investigative consumer report may contain information
                  concerning your character, general reputation, personal
                  characteristics, or mode of living.
                </Paragraph>
                <Paragraph>
                  <br />
                  You have the right, upon written request made within a
                  reasonable time, to request from the Company (1) whether an
                  investigative consumer report has been obtained about you, (2)
                  disclosure of the nature and scope of any investigative
                  consumer report and (3) a copy of your report. These reports
                  will be prepared by{" "}
                  <strong>
                    Victig Background Checks, 14442 S Center Point Way,
                    Bluffdale, UT 84065, 1-866-886-5644,{" "}
                    <a
                      href="https://victig.com/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      www.victig.com
                    </a>
                    .
                  </strong>
                </Paragraph>
              </Typography>
            </Col>
            <Col xs={24} lg={24} xl={24}>
              <p className="mt-5">
                <strong>APPLICANT SIGNATURE </strong>
              </p>
              {!onboardingdata?.fcra_signature_url && (
                <div>
                  <SignatureCanvas
                    penColor="black"
                    canvasProps={{ className: "signature" }}
                    ref={sigRef}
                    onEnd={handlefcraSignatureEnd}
                  />
                  <div className="text-center">
                    <Button type="dashed" onClick={clearfcraSignature}>
                      Clear Signature
                    </Button>
                  </div>
                </div>
              )}
              {onboardingdata?.fcra_signature_url && (
                <img
                  src={onboardingdata?.fcra_signature_url}
                  alt="fcra signature"
                  className="signature"
                />
              )}
              <span className="mt-5">&nbsp;</span>
            </Col>
            <Col span={24}>
              <div className="float-right">
                {current < steps.length && (
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Next
                  </Button>
                )}
              </div>
              <div className="float-left">
                {current > 0 && (
                  <Button onClick={() => prev()}>Previous</Button>
                )}
              </div>
            </Col>
          </Row>
        );
      // San Francisco
      case userData?.bgc_form_state === 4:
        return (
          <Row gutter={24}>
            <Col xs={24} lg={24} xl={24}>
              <Typography>
                <Paragraph>
                  <a
                    href="https://wieserp.s3.amazonaws.com/Victig/California/San+Francisco/FCRA+Summary+of+Rights+2023.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Click here to view FCRA Summary of Rights 2023{" "}
                  </a>
                </Paragraph>
                {/* <Col xs={24} lg={24} xl={24}> */}
                <Form.Item
                  name="fcra_terms_check"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(
                            new Error("Should accept agreement")
                          ),
                    },
                  ]}
                >
                  <Checkbox>
                    Please check this box if you have read the FCRA Summary of
                    Rights 2023{" "}
                  </Checkbox>
                </Form.Item>
                {/* </Col> */}
                <Paragraph>
                  <a
                    href="https://wieserp.s3.amazonaws.com/Victig/California/San+Francisco/San+Franscisco+Fair+Chance+OrdinanceNotice-Poster.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Click here to view San Franscisco Fair Chance Ordinance For
                    Hiring Notice{" "}
                  </a>
                </Paragraph>
                {/* <Col xs={24} lg={24} xl={24}> */}
                <Form.Item
                  name="Hiring_Notice"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(
                            new Error("Should accept agreement")
                          ),
                    },
                  ]}
                >
                  <Checkbox checked={onboardingdata?.fcra_signature_url}>
                    Please check this box if you have read the San Franscisco
                    Fair Chance Ordinance{" "}
                  </Checkbox>
                </Form.Item>
                {/* </Col> */}
                <Paragraph>
                  <a
                    href="https://wieserp.s3.amazonaws.com/Victig/California/San+Francisco/Excess+Telecom+NOTICE+REGARDING+BACKGROUNDCHECKS+PER+CALIFORNIA+LAW.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Click here to view NOTICE REGARDING BACKGROUND CHECKS PER
                    CALIFORNIA LAW{" "}
                  </a>
                </Paragraph>
                {/* <Col xs={24} lg={24} xl={24}> */}
                <Form.Item
                  name="BGC_CA_LAW"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(
                            new Error("Should accept agreement")
                          ),
                    },
                  ]}
                >
                  <Checkbox checked={onboardingdata?.fcra_signature_url}>
                    Please check this box if you would like to receive a copy of
                    an investigative consumer report at no charge if one is
                    obtained by the Company whenever you have a right to receive
                    such a copy under California law.{" "}
                  </Checkbox>
                </Form.Item>
                {/* </Col> */}
              </Typography>
            </Col>
            <Col xs={24} lg={24} xl={24}>
              <h5 className="mb-0">
                CALIFORNIA DISCLOSURE REGARDING “INVESTIGATIVE CONSUMER REPORT”
                BACKGROUND INVESTIGATION
              </h5>
              <hr />
              <Typography>
                <Paragraph>
                  <strong>Excess Telecom</strong> (the “Company”) may request an
                  investigative consumer report about you from a third party
                  consumer reporting agency, in connection with your employment
                  or application for employment (including volunteer
                  assignment(s), as applicable) and throughout your employment
                  if you are hired or retained, as allowed by law. An
                  “investigative consumer report” is a background report that
                  includes information from personal interviews (except in
                  California, where that term includes background reports with
                  or without information obtained from personal interviews). The
                  most common form of an investigative consumer report in
                  connection with your employment is a reference check through
                  personal interviews with sources such as your former employers
                  and associates, and other information sources. The
                  investigative consumer report may contain information
                  concerning your character, general reputation, personal
                  characteristics, or mode of living.
                </Paragraph>
                <Paragraph>
                  <br />
                  You have the right, upon written request made within a
                  reasonable time, to request from the Company (1) whether an
                  investigative consumer report has been obtained about you, (2)
                  disclosure of the nature and scope of any investigative
                  consumer report and (3) a copy of your report. These reports
                  will be prepared by{" "}
                  <strong>
                    Victig Background Checks, 14442 S Center Point Way,
                    Bluffdale, UT 84065, 1-866-886-5644,{" "}
                    <a
                      href="https://victig.com/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      www.victig.com
                    </a>
                    .
                  </strong>
                </Paragraph>
              </Typography>
            </Col>
            <Col xs={24} lg={24} xl={24}>
              <p className="mt-5">
                <strong>APPLICANT SIGNATURE </strong>
              </p>
              {!onboardingdata?.fcra_signature_url && (
                <div>
                  <SignatureCanvas
                    penColor="black"
                    canvasProps={{ className: "signature" }}
                    ref={sigRef}
                    onEnd={handlefcraSignatureEnd}
                  />
                  <div className="text-center">
                    <Button type="dashed" onClick={clearfcraSignature}>
                      Clear Signature
                    </Button>
                  </div>
                </div>
              )}
              {onboardingdata?.fcra_signature_url && (
                <img
                  src={onboardingdata?.fcra_signature_url}
                  alt="fcra signature"
                  className="signature"
                />
              )}
              <span className="mt-5">&nbsp;</span>
            </Col>
            <Col span={24}>
              <div className="float-right">
                {current < steps.length && (
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Next
                  </Button>
                )}
              </div>
              <div className="float-left">
                {current > 0 && (
                  <Button onClick={() => prev()}>Previous</Button>
                )}
              </div>
            </Col>
          </Row>
        );
      //  California Only
      case userData?.bgc_form_state === 3:
        return (
          <Row gutter={24}>
            <Col xs={24} lg={24} xl={24}>
              <Typography>
                <Paragraph>
                  <a
                    href="https://wieserp.s3.amazonaws.com/Victig/California/San+Francisco/FCRA+Summary+of+Rights+2023.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Click here to view FCRA Summary of Rights 2023{" "}
                  </a>
                </Paragraph>
                {/* <Col xs={24} lg={24} xl={24}> */}
                <Form.Item
                  name="fcra_terms_check"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(
                            new Error("Should accept agreement")
                          ),
                    },
                  ]}
                >
                  <Checkbox>
                    Please check this box if you have read the FCRA Summary of
                    Rights 2023{" "}
                  </Checkbox>
                </Form.Item>
                <Paragraph>
                  <a
                    href="https://wieserp.s3.amazonaws.com/Victig/California/San+Francisco/Excess+Telecom+NOTICE+REGARDING+BACKGROUNDCHECKS+PER+CALIFORNIA+LAW.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Click here to view NOTICE REGARDING BACKGROUND CHECKS PER
                    CALIFORNIA LAW{" "}
                  </a>
                </Paragraph>
                {/* <Col xs={24} lg={24} xl={24}> */}
                <Form.Item
                  name="BGC_CA_LAW"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(
                            new Error("Should accept agreement")
                          ),
                    },
                  ]}
                >
                  <Checkbox checked={onboardingdata?.fcra_signature_url}>
                    Please check this box if you would like to receive a copy of
                    an investigative consumer report at no charge if one is
                    obtained by the Company whenever you have a right to receive
                    such a copy under California law.{" "}
                  </Checkbox>
                </Form.Item>
                {/* </Col> */}
              </Typography>
            </Col>
            <Col xs={24} lg={24} xl={24}>
              <h5 className="mb-0">
                CALIFORNIA DISCLOSURE REGARDING “INVESTIGATIVE CONSUMER REPORT”
                BACKGROUND INVESTIGATION
              </h5>
              <hr />
              <Typography>
                <Paragraph>
                  <strong>Excess Telecom</strong> (the “Company”) may request an
                  investigative consumer report about you from a third party
                  consumer reporting agency, in connection with your employment
                  or application for employment (including volunteer
                  assignment(s), as applicable) and throughout your employment
                  if you are hired or retained, as allowed by law. An
                  “investigative consumer report” is a background report that
                  includes information from personal interviews (except in
                  California, where that term includes background reports with
                  or without information obtained from personal interviews). The
                  most common form of an investigative consumer report in
                  connection with your employment is a reference check through
                  personal interviews with sources such as your former employers
                  and associates, and other information sources. The
                  investigative consumer report may contain information
                  concerning your character, general reputation, personal
                  characteristics, or mode of living.
                </Paragraph>
                <Paragraph>
                  <br />
                  You have the right, upon written request made within a
                  reasonable time, to request from the Company (1) whether an
                  investigative consumer report has been obtained about you, (2)
                  disclosure of the nature and scope of any investigative
                  consumer report and (3) a copy of your report. These reports
                  will be prepared by{" "}
                  <strong>
                    Victig Background Checks, 14442 S Center Point Way,
                    Bluffdale, UT 84065, 1-866-886-5644,{" "}
                    <a
                      href="https://victig.com/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      www.victig.com
                    </a>
                    .
                  </strong>
                </Paragraph>
              </Typography>
            </Col>
            <Col xs={24} lg={24} xl={24}>
              <p className="mt-5">
                <strong>APPLICANT SIGNATURE </strong>
              </p>
              {!onboardingdata?.fcra_signature_url && (
                <div>
                  <SignatureCanvas
                    penColor="black"
                    canvasProps={{ className: "signature" }}
                    ref={sigRef}
                    onEnd={handlefcraSignatureEnd}
                  />
                  <div className="text-center">
                    <Button type="dashed" onClick={clearfcraSignature}>
                      Clear Signature
                    </Button>
                  </div>
                </div>
              )}
              {onboardingdata?.fcra_signature_url && (
                <img
                  src={onboardingdata?.fcra_signature_url}
                  alt="fcra signature"
                  className="signature"
                />
              )}
              <span className="mt-5">&nbsp;</span>
            </Col>
            <Col span={24}>
              <div className="float-right">
                {current < steps.length && (
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Next
                  </Button>
                )}
              </div>
              <div className="float-left">
                {current > 0 && (
                  <Button onClick={() => prev()}>Previous</Button>
                )}
              </div>
            </Col>
          </Row>
        );
      // New York
      case userData?.bgc_form_state === 2:
        return (
          <Row gutter={24}>
            <Col xs={24} lg={24} xl={24}>
              <Typography>
                <Paragraph>
                  <a
                    href="https://wieserp.s3.amazonaws.com/Victig/California/San+Francisco/FCRA+Summary+of+Rights+2023.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Click here to view FCRA Summary of Rights 2023{" "}
                  </a>
                </Paragraph>
                {/* <Col xs={24} lg={24} xl={24}> */}
                <Form.Item
                  name="fcra_terms_check"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(
                            new Error("Should accept agreement")
                          ),
                    },
                  ]}
                >
                  <Checkbox>
                    Please check this box if you have read the FCRA Summary of
                    Rights 2023{" "}
                  </Checkbox>
                </Form.Item>
                <Paragraph>
                  <a
                    href="https://wieserp.s3.amazonaws.com/Victig/California/New+York/NY+Article+23A.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Click here to view NY Article 23A{" "}
                  </a>
                </Paragraph>
                <Form.Item
                  name="NY_Rights"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(
                            new Error("Should accept agreement")
                          ),
                    },
                  ]}
                >
                  <Checkbox checked={onboardingdata?.fcra_signature_url}>
                    Please check this box if you have read the NY Article 23A{" "}
                  </Checkbox>
                </Form.Item>
              </Typography>
            </Col>
            <Col xs={24} lg={24} xl={24}>
              <h5 className="mb-0">
                DISCLOSURE REGARDING BACKGROUND INVESTIGATION
              </h5>
              <hr />
              <Typography>
                <Paragraph>
                  <strong>Excess Telecom</strong> (“the Company”) may obtain
                  information about you from a third party consumer reporting
                  agency for employment purposes. Thus, you may be the subject
                  of a “consumer report” which may include information about
                  your character, general reputation, personal characteristics,
                  and/or mode of living. These reports may contain information
                  regarding your criminal history, social security verification,
                  motor vehicle records (“driving records”), verification of
                  your education or employment history, or other background
                  checks.
                  <Paragraph>
                    <br />
                    The investigations will be conducted by{" "}
                    <strong>
                      Victig Background Checks, 14442 Center Point Way,
                      Bluffdale, UT 84065, 1-866-886-5644,
                      <a
                        href="https://victig.com/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        www.victig.com
                      </a>
                      .
                    </strong>{" "}
                  </Paragraph>
                </Paragraph>
              </Typography>
            </Col>
            <Col xs={24} lg={24} xl={24}>
              <p className="mt-5">
                <strong>APPLICANT SIGNATURE </strong>
              </p>
              {/* {!onboardingdata?.fcra_signature_url && ( */}
              {/* // )} */}
              <div>
                <SignatureCanvas
                  penColor="black"
                  canvasProps={{ className: "signature" }}
                  ref={sigRef}
                  onEnd={handlefcraSignatureEnd}
                />
                <div className="text-center">
                  <Button type="dashed" onClick={clearfcraSignature}>
                    Clear Signature
                  </Button>
                </div>
              </div>
              {onboardingdata?.fcra_signature_url && (
                <img
                  src={onboardingdata?.fcra_signature_url}
                  alt="fcra signature"
                  className="signature"
                />
              )}
              <span className="mt-5">&nbsp;</span>
            </Col>
            <Col span={24}>
              <div className="float-right">
                {current < steps.length && (
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Next
                  </Button>
                )}
              </div>
              <div className="float-left">
                {current > 0 && (
                  <Button onClick={() => prev()}>Previous</Button>
                )}
              </div>
            </Col>
          </Row>
        );
      default:
        return (
          <Row gutter={24}>
            <Col xs={24} lg={24} xl={24}>
              <Typography>
                <Paragraph>
                  <a
                    href="https://wieserp.s3.amazonaws.com/Victig/California/San+Francisco/FCRA+Summary+of+Rights+2023.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Click here to view FCRA Summary of Rights 2023{" "}
                  </a>
                </Paragraph>
                <Form.Item
                  name="fcra_terms_check"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(
                            new Error("Should accept agreement")
                          ),
                    },
                  ]}
                >
                  <Checkbox>
                    Please check this box if you have read the FCRA Summary of
                    Rights 2023{" "}
                  </Checkbox>
                </Form.Item>
                {/* </Col> */}
              </Typography>
            </Col>
            <Col xs={24} lg={24} xl={24}>
              <h5 className="mb-0">
                DISCLOSURE REGARDING BACKGROUND INVESTIGATION
              </h5>
              <hr />
              <Typography>
                <Paragraph>
                  <strong>Excess Telecom</strong> (the “Company”) may obtain
                  information about you from a third party consumer reporting
                  agency for employment purposes. Thus, you may be the subject
                  of a “consumer report” which may include information about
                  your character, general reputation, personal characteristics,
                  and/or mode of living. These reports may contain information
                  regarding your criminal history, social security verification,
                  motor vehicle records (“driving records”), verification of
                  your education or employment history, or other background
                  checks.
                </Paragraph>
                <br />
                <Paragraph>
                  The investigations will be conducted by{" "}
                  <strong>
                    Victig Background Checks, 14442 S Center Point Way,
                    Bluffdale, UT 84065, 1-866-886-5644,{" "}
                    <a
                      href="https://victig.com/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      www.victig.com
                    </a>
                    .
                  </strong>
                </Paragraph>
              </Typography>
            </Col>
            <Col xs={24} lg={24} xl={24}>
              <p className="mt-5">
                <strong>APPLICANT SIGNATURE </strong>
              </p>
              {!onboardingdata?.fcra_signature_url && (
                <div>
                  <SignatureCanvas
                    penColor="black"
                    canvasProps={{ className: "signature" }}
                    ref={sigRef}
                    onEnd={handlefcraSignatureEnd}
                  />
                  <div className="text-center">
                    <Button type="dashed" onClick={clearfcraSignature}>
                      Clear Signature
                    </Button>
                  </div>
                </div>
              )}
              {onboardingdata?.fcra_signature_url && (
                <img
                  src={onboardingdata?.fcra_signature_url}
                  alt="fcra signature"
                  className="signature"
                />
              )}

              <span className="mt-5">&nbsp;</span>
            </Col>
            <Col span={24}>
              <div className="float-right">
                {current < steps.length && (
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Next
                  </Button>
                )}
              </div>
              <div className="float-left">
                {current > 0 && (
                  <Button onClick={() => prev()}>Previous</Button>
                )}
              </div>
            </Col>
          </Row>
        );
    }
  };

  const renderTermsAgreement = () => {
    switch (true) {
      case userData?.bgc_form_state === 3 ||
        userData?.bgc_form_state === 4 ||
        userData?.bgc_form_state === 5:
        return (
          <div className="container-fluid">
            <h5 className="mb-0">
              CALIFORNIA ACKNOWLEDGEMENT AND AUTHORIZATION FOR BACKGROUND
              INVESTIGATION
            </h5>
            <hr />
            <Row gutter={24}>
              <Col xs={24} lg={24} xl={24}>
                <Typography>
                  <Paragraph>
                    I acknowledge receipt of the separate documents entitled
                    DISCLOSURE REGARDING BACKGROUND INVESTIGATION, A SUMMARY OF
                    YOUR RIGHTS UNDER THE FAIR CREDIT REPORTING ACT, OTHER STATE
                    LAW NOTICES and CA DISCLOSURE (NOTICE REGARDING BACKGROUND
                    CHECKS PER CALIFORNIA LAW) and certify that I have read and
                    understand each of those documents. I hereby authorize the
                    obtaining of “consumer reports” by{" "}
                    <strong>Excess Telecom</strong> (the “Company“) at any time
                    after receipt of this authorization and throughout my
                    employment, if applicable (except in CA which requires
                    additional consent). To this end, I hereby authorize,
                    without reservation, any law enforcement agency,
                    administrator, state or federal agency, institution, school
                    or university (public or private), information service
                    bureau, employer, or insurance company to furnish any and
                    all background information requested by{" "}
                    <strong>
                      Victig Background Checks, 14442 S Center Point Way,
                      Bluffdale, UT 84065, 1-866-886-5644, www.victig.com
                    </strong>{" "}
                    and/or the Company. I agree that a facsimile (“fax”),
                    electronic or photographic copy of this Authorization shall
                    be as valid as the original.
                  </Paragraph>
                </Typography>
              </Col>

              {/* OTHER STATE LAW NOTICES for Los Angeles */}
              {userData?.bgc_form_state_law_notice === 5 && (
                <Col xs={24} lg={24} xl={24}>
                  <br />
                  <Form.Item
                    name="bgc_file_access_check"
                    valuePropName="checked"
                  // rules={[
                  //   {
                  //     validator: (_, value) =>
                  //       value
                  //         ? Promise.resolve()
                  //         : Promise.reject(
                  //             new Error("Should accept agreement")
                  //           ),
                  //   },
                  // ]}
                  >
                    <Checkbox>
                      Please check this box to receive a copy of the Notice to
                      Applicants & Employees Fair Chance Initiative for Hiring
                      Ordinance.{" "}
                    </Checkbox>
                  </Form.Item>
                </Col>
              )}
              {/* OTHER STATE LAW NOTICES for San Francisco */}
              {userData?.bgc_form_state_law_notice === 6 && (
                <Col xs={24} lg={24} xl={24}>
                  <br />
                  <Form.Item
                    name="bgc_file_access_check"
                    valuePropName="checked"
                  // rules={[
                  //   {
                  //     validator: (_, value) =>
                  //       value
                  //         ? Promise.resolve()
                  //         : Promise.reject(
                  //             new Error("Should accept agreement")
                  //           ),
                  //   },
                  // ]}
                  >
                    <Checkbox>
                      Please check this box to receive a copy of the San
                      Francisco Fair Chance Ordinance Notice.{" "}
                    </Checkbox>
                  </Form.Item>
                </Col>
              )}
              <Col xs={24} lg={24} xl={24}>
                <br />
                <Form.Item
                  name="terms_check"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(
                            new Error("Should accept agreement")
                          ),
                    },
                  ]}
                >
                  <Checkbox>
                    I hereby confirm my acceptance of the terms by checking the
                    box provided. I understand and acknowledge that this
                    decision is in relation to my residency or employment with
                    Company/Employer.{" "}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col xs={24} lg={24} xl={24}>
                <p>
                  <strong>APPLICANT SIGNATURE </strong>
                </p>
                <SignatureCanvas
                  penColor="black"
                  canvasProps={{ className: "signature" }}
                  ref={sigRef}
                  onEnd={handleSignatureEnd}
                />
                <div className="text-center">
                  <Button type="dashed" onClick={clearSignature}>
                    Clear Signature
                  </Button>
                </div>
                <span className="mt-5">&nbsp;</span>
              </Col>
              <Col span={24}>
                <div className="float-right">
                  {current < steps.length && (
                    <Button type="primary" htmlType="submit" loading={loading}>
                      Next
                    </Button>
                  )}
                </div>
                <div className="float-left">
                  {current > 0 && (
                    <Button onClick={() => prev()}>Previous</Button>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        );
      default:
        return (
          <div className="container-fluid">
            <h5 className="mb-0">
              ACKNOWLEDGEMENT AND AUTHORIZATION FOR BACKGROUND INVESTIGATION
            </h5>
            <hr />
            <Row gutter={24}>
              <Col xs={24} lg={24} xl={24}>
                <Typography>
                  <Paragraph>
                    I acknowledge receipt of the separate documents entitled
                    DISCLOSURE REGARDING BACKGROUND INVESTIGATION, A SUMMARY OF
                    YOUR RIGHTS UNDER THE FAIR CREDIT REPORTING ACT and OTHER
                    STATE LAW NOTICES and certify that I have read and
                    understand each of those documents. I hereby authorize the
                    obtaining of “consumer reports” and/or “investigative
                    consumer reports” by <strong>Excess Telecom</strong> (the
                    “Company“) at any time after receipt of this authorization
                    and throughout my employment, if applicable. To this end, I
                    hereby authorize, without reservation, any law enforcement
                    agency, administrator, state or federal agency, institution,
                    school or university (public or private), information
                    service bureau, employer, or insurance company to furnish
                    any and all background information requested by{" "}
                    <strong>
                      Victig Background Checks, 14442 Center Point Way,
                      Bluffdale, UT 84065, 1-866-886-5644, www.victig.com
                    </strong>{" "}
                    .and/or the Company. I agree that a facsimile (“fax”),
                    electronic or photographic copy of this Authorization shall
                    be as valid as the original.
                  </Paragraph>
                </Typography>
              </Col>
              {/* OTHER STATE LAW NOTICES for New York applicants only */}
              {userData?.bgc_form_state_law_notice === 1 && (
                <Col xs={24} lg={24} xl={24}>
                  <br />
                  <Form.Item
                    name="bgc_file_access_check"
                    valuePropName="checked"
                  // rules={[
                  //   {
                  //     validator: (_, value) =>
                  //       value
                  //         ? Promise.resolve()
                  //         : Promise.reject(
                  //             new Error("Should accept agreement")
                  //           ),
                  //   },
                  // ]}
                  >
                    <Checkbox>
                      Upon request, you will be informed whether or not a
                      consumer report was requested by the Company, and if such
                      report was requested, informed of the name and address of
                      the consumer reporting agency that furnished the report.
                      You have the right to inspect and receive a copy of any
                      investigative consumer report requested by the Company by
                      contacting the consumer reporting agency identified above
                      directly. By signing below, you acknowledge receipt of
                      Article 23-A of the New York Correction Law.{" "}
                    </Checkbox>
                  </Form.Item>
                </Col>
              )}
              {/* OTHER STATE LAW NOTICES for New York City only*/}
              {userData?.bgc_form_state_law_notice === 2 && (
                <Col xs={24} lg={24} xl={24}>
                  <br />
                  <Form.Item
                    name="bgc_file_access_check"
                    valuePropName="checked"
                  // rules={[
                  //   {
                  //     validator: (_, value) =>
                  //       value
                  //         ? Promise.resolve()
                  //         : Promise.reject(
                  //             new Error("Should accept agreement")
                  //           ),
                  //   },
                  // ]}
                  >
                    <Checkbox>
                      By signing this form, you further authorize the Company to
                      provide you with a copy of your consumer report, the New
                      York City Fair Chance Act Notice form, and any other
                      documents, to the extent required by law, at the mailing
                      address and/or email address you provide to the Company.{" "}
                    </Checkbox>
                  </Form.Item>
                </Col>
              )}
              {/* OTHER STATE LAW NOTICES for Washington State only*/}
              {userData?.bgc_form_state_law_notice === 7 && (
                <Col xs={24} lg={24} xl={24}>
                  <br />
                  <Form.Item
                    name="bgc_file_access_check"
                    valuePropName="checked"
                  // rules={[
                  //   {
                  //     validator: (_, value) =>
                  //       value
                  //         ? Promise.resolve()
                  //         : Promise.reject(
                  //             new Error("Should accept agreement")
                  //           ),
                  //   },
                  // ]}
                  >
                    <Checkbox>
                      You also have the right to request from the consumer
                      reporting agency a written summary of your rights and
                      remedies under the Washington Fair Credit Reporting Act.{" "}
                    </Checkbox>
                  </Form.Item>
                </Col>
              )}
              {/* OTHER STATE LAW NOTICES for Oklahoma only*/}
              {userData?.bgc_form_state_law_notice === 4 && (
                <Col xs={24} lg={24} xl={24}>
                  <br />
                  <Form.Item
                    name="bgc_file_access_check"
                    valuePropName="checked"
                  // rules={[
                  //   {
                  //     validator: (_, value) =>
                  //       value
                  //         ? Promise.resolve()
                  //         : Promise.reject(
                  //             new Error("Should accept agreement")
                  //           ),
                  //   },
                  // ]}
                  >
                    <Checkbox>
                      Please check this box if you would like to receive a copy
                      of a consumer report if one is obtained by the Company{" "}
                    </Checkbox>
                  </Form.Item>
                </Col>
              )}
              {/* OTHER STATE LAW NOTICES for Minnesota only*/}
              {userData?.bgc_form_state_law_notice === 3 && (
                <Col xs={24} lg={24} xl={24}>
                  <br />
                  <Form.Item
                    name="bgc_file_access_check"
                    valuePropName="checked"
                  // rules={[
                  //   {
                  //     validator: (_, value) =>
                  //       value
                  //         ? Promise.resolve()
                  //         : Promise.reject(
                  //             new Error("Should accept agreement")
                  //           ),
                  //   },
                  // ]}
                  >
                    <Checkbox>
                      You have the right to submit a written request to the
                      consumer reporting agency for a complete and accurate
                      disclosure of the nature and scope of any consumer report
                      the Company ordered about you. The consumer reporting
                      agency must provide you with this disclosure within five
                      business days after its receipt of your request or the
                      report was requested by the Company, whichever date is
                      later. Please check this box if you would like to receive
                      a copy of a consumer report if one is obtained by the
                      Company{" "}
                    </Checkbox>
                  </Form.Item>
                </Col>
              )}
              <Col xs={24} lg={24} xl={24} className="mt-3">
                <Form.Item
                  name="terms_check"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(
                            new Error("Should accept agreement")
                          ),
                    },
                  ]}
                >
                  <Checkbox>
                    I hereby confirm my acceptance of the terms by checking the
                    box provided, indicating my agreement to receive a copy of
                    my background report. I understand and acknowledge that this
                    decision is in relation to my residency or employment with
                    Company/Employer.{" "}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col xs={24} lg={24} xl={24}>
                <p>
                  <strong>APPLICANT SIGNATURE </strong>
                </p>
                <SignatureCanvas
                  penColor="black"
                  canvasProps={{ className: "signature" }}
                  ref={sigRef}
                  onEnd={handleSignatureEnd}
                />
                <div className="text-center">
                  <Button type="dashed" onClick={clearSignature}>
                    Clear Signature
                  </Button>
                </div>
                <span className="mt-5">&nbsp;</span>
              </Col>
              <Col span={24}>
                <div className="float-right">
                  {current < steps.length && (
                    <Button type="primary" htmlType="submit" loading={loading}>
                      Next
                    </Button>
                  )}
                </div>
                <div className="float-left">
                  {current > 0 && (
                    <Button onClick={() => prev()}>Previous</Button>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        );
    }
  };
  const getValidationRules = (type, limit) => {
  
    switch (type) {
      case 'Alphanumeric':
        return [
          {
            required: true,
            message: 'Please input answer',
          },
          {
            type: 'string',
            pattern: /^[a-zA-Z0-9 ]*$/,
            message: 'Answer must be alphanumeric',
          },
          {
            max: limit,
            message: `Answer must be ${limit} characters length`,
          },
        ];
      case 'Numbers':
        return [
          {
            required: true,
            message: 'Please input answer',
          },
          {
            type: 'string',
            pattern: /^[0-9]*$/,
            message: 'Answer must be numeric value',
          },
          {
            max: limit,
            message: `Answer must be ${limit} characters length`,
          },
        ];
      default:
        return [
          {
            required: true,
            message: 'Please input answer',
          },
        ];
    }
  };
  const getQuestionById = (id) => {
    return question.find((question) => question.id === id);
  };
  const setQuestionTypeAndLimit1 = (name, type, limit) => {
    setQuestionTypeAndLimit((prev) => ({
      ...prev,
      [name]: { type, limit },
    }));
  };
  const handleSelectChange = (value, name) => {
    const selectedQuestion = getQuestionById(value);
    console.log('Selected question:', selectedQuestion);
    form.setFieldsValue({
      [`${name}_answer`]: '',
    });
    // Store the selected question type and limit in the state
    setQuestionTypeAndLimit1(name, selectedQuestion?.type, selectedQuestion?.limit);
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const getFilteredOptions = (currentName) => {
    return (question || [])
      .filter((d) => {
        return !Object.keys(selectedValues).some(
          (key) => key !== currentName && selectedValues[key] === d.id
        );
      })
      .map((d) => ({
        value: d.id,
        label: d.question,
      }));
  };

  return (
    <>
      <Layout className="layout">
        <TopHeader />
        <Spin spinning={dataloading}>
          <div className="container p-0 form-holder-step">
            <Steps
              type="navigation"
              size="small"
              className="site-navigation-steps"
              current={current - 1}
              items={items}
              responsive={false}
            />
          </div>
          <Content
            className="container p-0"
            style={{ minHeight: "calc(100vh - 182px)" }}
          >
            <div className="site-layout-content">
              {/* <div style={contentStyle}>{steps[current].content}</div> */}
              <div>
                {current === 1 && (
                  <div className="container-fluid">
                    <h5 className="mb-0">Candidate Details</h5>
                    <hr />
                    <Form
                      form={form}
                      name="candidateprofile"
                      layout="vertical"
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                    >
                      <Row gutter={24}>
                        {!terms && (
                          <Col xs={24} lg={24} xl={24}>
                            <Form.Item
                              name="disclaimer_check"
                              valuePropName="checked"
                              rules={[
                                {
                                  validator: (_, value) =>
                                    value
                                      ? Promise.resolve()
                                      : Promise.reject(
                                        new Error("Should accept consent")
                                      ),
                                },
                              ]}
                            >
                              <Checkbox onChange={consentHandler}>
                                {source === 3 ? (
                                  <span>
                                    Agents are responsible for maintaining the
                                    inventory in their possession and must
                                    report any lost or damaged devices to their
                                    manager immediately. All agents must also
                                    return any inventory issued to them by their
                                    current marketer immediately before
                                    transferring to another organization under
                                    Excess Telecom. Failure to return all
                                    inventory promptly and in good condition may
                                    result in penalties or transfer denial.{" "}
                                    <b>
                                      I confirm that the assigned inventory has
                                      been returned successfully by checking.
                                    </b>
                                  </span>
                                ) : (
                                  <span>
                                    Agents are responsible for maintaining the
                                    inventory in their possession and must
                                    report any lost or damaged devices to their
                                    manager immediately. All agents must also
                                    return any inventory issued to them by their
                                    current marketer immediately before a
                                    departure. Failure to return all inventory
                                    promptly and in good condition may result in
                                    penalties.{" "}
                                    <b>
                                      I acknowledge and agree to the terms of
                                      the inventory policy by checking.
                                    </b>
                                  </span>
                                )}
                              </Checkbox>
                            </Form.Item>
                          </Col>
                        )}
                        {consent && (
                          <>
                            <Col xs={24} lg={8} xl={8}>
                              <Form.Item
                                label="First Name"
                                name="first_name"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input first name",
                                  },
                                ]}
                              >
                                <Input placeholder="Enter Firstname" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} lg={8} xl={8}>
                              <Form.Item
                                label="Middle Name (Optional)"
                                name="middle_name"
                                rules={[
                                  {
                                    required: false,
                                    message: "Please input middle name",
                                  },
                                ]}
                              >
                                <Input placeholder="Enter Middle name" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} lg={8} xl={8}>
                              <Form.Item
                                label="Last Name"
                                name="last_name"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input last name",
                                  },
                                ]}
                              >
                                <Input placeholder="Enter Lastname" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} lg={12} xl={12}>
                              <Form.Item
                                label="Email Address"
                                name="email"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input valid email!",
                                  },
                                ]}
                              >
                                <Input type="email" placeholder="Enter Email" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} lg={12} xl={12}>
                              <Form.Item
                                label="Phone Number"
                                name="mobile_number"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input Phone Number!",
                                  },
                                ]}
                              >
                                <Input disabled placeholder="Enter Mobile no" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} lg={12} xl={12}>
                              <Form.Item
                                label="Date of birth"
                                name="dob"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input valid date!",
                                  },
                                ]}
                                extra="Must be above 18 years old"
                              >
                                <DatePicker
                                  //defaultValue={moment("2015-01-01", "MM-DD-YYYY")}
                                  style={{ width: "100%" }}
                                  format={"MM-DD-YYYY"}
                                  placeholder="Date of Birth"
                                  disabledDate={(current) =>
                                    current.isAfter(
                                      moment().subtract(18, "year")
                                    )
                                  }
                                  showToday={false}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} lg={12} xl={12}>
                              <Form.Item
                                label="SSN Number"
                                name="ssn_number"
                                rules={[
                                  {
                                    required: true,
                                    pattern: new RegExp(
                                      /^(?!(000|666))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$|^(?!(000|666))\d{3}(?!00)\d{2}(?!0000)\d{4}$/
                                    ),
                                    message: "Please input valid SSN Number!",
                                  },
                                ]}
                              >
                                <Input placeholder="Enter SSN number" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} lg={12} xl={12}>
                              <Form.Item
                                label="Gender"
                                name="gender_id"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select your gender",
                                  },
                                ]}
                              >
                                <Radio.Group>
                                  <Radio value={1}>Male</Radio>
                                  <Radio value={2}>Female</Radio>
                                </Radio.Group>
                              </Form.Item>
                            </Col>
                            <Col xs={24} lg={12} xl={12}>
                              <Form.Item
                                label="Shirt Size"
                                name="shirt_size"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input Shirt Size",
                                  },
                                ]}
                              >
                                <Select
                                  style={{
                                    width: "100%",
                                  }}
                                  placeholder="Select shirt size"
                                  options={[
                                    {
                                      value: "XS",
                                      label: "XS",
                                    },
                                    {
                                      value: "S",
                                      label: "S",
                                    },
                                    {
                                      value: "M",
                                      label: "M",
                                    },
                                    {
                                      value: "L",
                                      label: "L",
                                    },
                                    {
                                      value: "XL",
                                      label: "XL",
                                    },
                                    {
                                      value: "XXL",
                                      label: "XXL",
                                    },
                                    {
                                      value: "XXXL",
                                      label: "XXXL",
                                    },
                                  ]}
                                />
                              </Form.Item>
                            </Col>
                            {source === 1 ? (<>
                             
                              <Col xs={24} lg={8} xl={8}>
                                <Form.Item
                                  hidden={onboardingdata?.security_question1 !== null}
                                  label="Security Question 1"
                                  name="security_question1"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select security question",
                                    },
                                  ]}
                                >
                                  <Select
                                    style={{ width: "100%" }}
                                    placeholder="Select question"
                                    options={getFilteredOptions("security_question1")}
                                    onChange={(value) => handleSelectChange(value, "security_question1")}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} lg={8} xl={8}>
                                <Form.Item
                                  hidden={onboardingdata?.security_question1 !== null}
                                  label="Security Question 2"
                                  name="security_question2"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select security question",
                                    },
                                  ]}
                                >
                                  <Select
                                    style={{ width: "100%" }}
                                    placeholder="Select question"
                                    options={getFilteredOptions("security_question2")}
                                    onChange={(value) => handleSelectChange(value, "security_question2")}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} lg={8} xl={8}>
                                <Form.Item
                                  hidden={onboardingdata?.security_question1 !== null}
                                  label="Security Question 3"
                                  name="security_question3"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select security question",
                                    },
                                  ]}
                                >
                                  <Select
                                    style={{ width: "100%" }}
                                    placeholder="Select question"
                                    options={getFilteredOptions("security_question3")}
                                    onChange={(value) => handleSelectChange(value, "security_question3")}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} lg={8} xl={8}>
                                <Form.Item
                                  hidden={onboardingdata?.security_question1 !== null}
                                  name="security_question1_answer"
                                  rules={getValidationRules(
                                    questionTypeAndLimit.security_question1?.type,
                                    questionTypeAndLimit.security_question1?.limit
                                  )}
                                >
                                  <Input
                                    placeholder="Security Question 1 Answer"
                                    maxLength={questionTypeAndLimit.security_question1?.limit}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} lg={8} xl={8}>
                                <Form.Item
                                  hidden={onboardingdata?.security_question1 !== null}
                                  name="security_question2_answer"
                                  rules={getValidationRules(
                                    questionTypeAndLimit.security_question2?.type,
                                    questionTypeAndLimit.security_question2?.limit
                                  )}
                                >
                                  <Input
                                    placeholder="Security Question 2 Answer"
                                    maxLength={questionTypeAndLimit.security_question2?.limit}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} lg={8} xl={8}>
                                <Form.Item
                                  hidden={onboardingdata?.security_question1 !== null}
                                  name="security_question3_answer"
                                  rules={getValidationRules(
                                    questionTypeAndLimit.security_question3?.type,
                                    questionTypeAndLimit.security_question3?.limit
                                  )}
                                >
                                  <Input
                                    placeholder="Security Question 3 Answer"
                                    maxLength={questionTypeAndLimit.security_question3?.limit}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} lg={24} xl={24} className="mb-5">
                              <small style={{fontSize:"85%", fontWeight:"bold"}}><span style={{color:"red"}}>*</span> Disclaimer: Field agents are required to personally set up and provide valid security question answers in WiES during onboarding. Non-compliance with this protocol will result in profile rejection. Field agents valid email addresses and phone numbers are required to receive necessary credentials and notifications.

                              </small>
                              </Col>
                            </>) :  <Col xs={24} lg={24} xl={24} className="mb-5">
                              <small style={{fontSize:"85%", fontWeight:"bold"}}><span style={{color:"red"}}>*</span> Disclaimer: Field agents valid email addresses and phone numbers are required to receive necessary credentials and notifications .

                              </small>
                              </Col>}

                          </>
                        )}

                        <Col span={24}>
                          <div className="float-right">
                            {current < steps.length - 1 && (
                              <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                              >
                                Next
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                )}

                {current === 2 && (
                  <div className="container-fluid">
                    <h5 className="mb-0">Address Details</h5>
                    <hr />
                    <Form
                      form={form}
                      name="basic"
                      layout="vertical"
                      onFinish={onFinishAddress}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                    >
                      <Row gutter={24}>
                        <Col xs={24} lg={8} xl={8}>
                          <Form.Item
                            label="Street Address1"
                            name="street_address1"
                            rules={[
                              {
                                required: true,
                                message: "Please input valid address!",
                              },
                            ]}
                          >
                            <Input placeholder="Enter Address 1" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} lg={8} xl={8}>
                          <Form.Item
                            label="Street Address2"
                            name="street_address2"
                            rules={[
                              {
                                required: false,
                                message: "Please input valid address!",
                              },
                            ]}
                          >
                            <Input placeholder="Enter Address 2" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} lg={8} xl={8}>
                          <Form.Item
                            label="City"
                            name="city"
                            rules={[
                              {
                                required: true,
                                message: "Please input city!",
                              },
                            ]}
                          >
                            <Input placeholder="Enter City" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} lg={8} xl={8}>
                          <Form.Item
                            label="State"
                            name="state"
                            rules={[
                              {
                                required: true,
                                message: "Please input State!",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              style={{
                                width: "100%",
                              }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              filterSort={(optionA, optionB) =>
                                (optionA?.label ?? "")
                                  .toLowerCase()
                                  .localeCompare(
                                    (optionB?.label ?? "").toLowerCase()
                                  )
                              }
                              placeholder="Select State"
                              options={(shortstate || []).map((d) => ({
                                value: d.short_name,
                                label: d.name,
                              }))}
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} lg={8} xl={8}>
                          <Form.Item
                            label="Zipcode"
                            name="zip"
                            rules={[
                              {
                                required: true,
                                pattern: new RegExp(/^[0-9]+$/),
                                message: "Please input valid Zipcode!",
                              },
                            ]}
                          >
                            <Input maxLength={5} placeholder="Enter Zipcode" />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <div className="float-left">
                            {current > 0 && (
                              <Button onClick={() => prev()}>Previous</Button>
                            )}
                          </div>
                          <div className="float-right">
                            {current < steps.length - 1 && (
                              <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                              >
                                Next
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                )}
                {current === 3 && (
                  <div className="container-fluid">
                    <h5 className="mb-0">RAD Details</h5>
                    <hr />
                    <Form
                      form={form}
                      name="basic"
                      layout="vertical"
                      onFinish={onFinishAdditional}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                    >
                      <Row gutter={24}>
                        <Col xs={24} lg={12} xl={12}>
                          <Form.Item
                            name="rad_check"
                            label="Do you have a RAD number? *"
                            extra="Make sure to ask your manager if you aren't sure! If you already have a RAD number and you don't input it here you won't be able to work with us!"
                            rules={[
                              {
                                required: true,
                                message: "Please select options",
                              },
                            ]}
                            initialValue={1}
                          >
                            <Radio.Group onChange={onChangeRAD}>
                              <Radio value={1}>Yes</Radio>
                              <Radio value={0}>No</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>

                        {radvalue === 0 ? (
                          <Col xs={24} lg={24} xl={24}>
                            <a
                              href="https://lifelinerad.org/rad/#/Landing"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Button type="primary" htmlType="button">
                                Click to register with USAC for RAD ID
                              </Button>
                            </a>
                          </Col>
                        ) : (
                          <Col xs={24} lg={12} xl={12}>
                            <Form.Item
                              label="RAD ID"
                              name="rad_id"
                              rules={[
                                {
                                  pattern: new RegExp("^[a-zA-Z0-9]{0,9}$"),
                                  required: true,
                                  message: "Please input RAD ID",
                                },
                                {
                                  pattern: /^[A-Za-z0-9]+$/,
                                  message: "Please enter a valid RAD ID",
                                },
                              ]}
                            >
                              <Input maxLength={9} placeholder="Enter RAD ID" />
                            </Form.Item>
                          </Col>
                        )}

                        <br />
                        <br />
                        <br />

                        <Col span={24}>
                          <div className="float-left">
                            {current > 0 && (
                              <Button onClick={() => prev()}>Previous</Button>
                            )}
                          </div>

                          <div className="float-right">
                            {current > 0 && (
                              <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                              >
                                Next
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                )}
                {current === 4 && (
                  <div className="container-fluid">
                    <h5 className="mb-0">Other Details</h5>
                    <hr />
                    <Form
                      form={form}
                      name="basic"
                      layout="vertical"
                      onFinish={onFinishProof}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                    >
                      <Row gutter={24}>
                        <Col xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Government Picture ID "
                            name="government_document"
                            rules={[
                              {
                                required: true,
                                message: "Please upload valid document",
                              },
                            ]}
                          >
                            <FileGovUpload
                              fileList={fileList}
                              setFileList={setFileList}
                              existingFile={governmenturl}
                              setExistingFile={setgovernmenturl}
                              form={form}
                              name="government_document"
                            />
                          </Form.Item>
                        </Col>
                        {source === 1 && (
                        <Col xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Upload Profile Picture"
                            name="photo_copy"
                            rules={[
                              {
                                required: true,
                                message: "Please upload your picture",
                              },
                            ]}
                          >
                            <FileUpload
                              fileList={fileList1}
                              setFileList={setFileList1}
                              existingFile={photourl}
                              setExistingFile={setphotourl}
                              form={form}
                              name="photo_copy"
                            />
                          </Form.Item>
                        </Col>)}
                        {source !== 1 && (
                          <Col xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Upload Profile Picture 2"
                            name="photo_copy_2"
                            rules={[
                              {
                                required: true,
                                message: "Please upload profile picture 2",
                              },
                            ]}
                          >
                            <FileUpload2
                              fileList={fileList6}
                              setFileList={setFileList6}
                              existingFile={photourl2}
                              setExistingFile={setphotourl2}
                              form={form}
                              name="photo_copy_2"
                            />
                          </Form.Item>
                        </Col>
                        )}
                        <Col xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Retailer / Agent Agreement"
                            name="retaileragreement"
                            rules={[
                              {
                                required: false,
                                message: "Please upload document",
                              },
                            ]}
                          >
                            <FileUploadDoc
                              fileList={fileList2}
                              setFileList={setFileList2}
                              existingFile={retailerurl}
                              setExistingFile={setretailerurl}
                              form={form}
                              name="retaileragreement"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Supporting Documents"
                            name="supportdocuments"
                            rules={[
                              {
                                required: false,
                                message: "Please upload document",
                              },
                            ]}
                          >
                            <FileUploadDoc
                              fileList={fileList3}
                              setFileList={setFileList3}
                              existingFile={supporturl}
                              setExistingFile={setsupporturl}
                              form={form}
                              name="supportdocuments"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <div className="float-left">
                            {current > 0 && (
                              <Button
                                onClick={() => {
                                  prev();
                                  setFileList([]);
                                  setFileList1([]);
                                  setFileList2([]);
                                  setFileList3([]);
                                }}
                              >
                                Previous
                              </Button>
                            )}
                          </div>
                          <div className="float-right">
                            {current > 0 && (
                              <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                              >
                                Next
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                )}
                {current === 5 && (
                  <div className="container-fluid">
                    <Form
                      form={form}
                      name="basic"
                      layout="vertical"
                      onFinish={onFinishTerms1}
                      onFinishFailed={onFinishFailed1}
                    >
                      {() => renderFCRAAgreement()}
                    </Form>
                  </div>
                )}
                {current === 6 && (
                  <Form
                    form={form}
                    name="basic"
                    layout="vertical"
                    onFinish={onFinishTerms}
                    onFinishFailed={onFinishFailed}
                  >
                    {() => renderTermsAgreement()}
                  </Form>
                )}
                {current === 7 && (
                  <div className="container-fluid">
                    {onboardingdata?.status >= 2 ? (
                      <>
                        <Row gutter={24}>
                          <Col span={24}>
                            {/* <Title className="text-center mt-3" level={4}>
                              Candidate Profile Progress
                            </Title> */}

                            <Descriptions
                              style={{ maxWidth: "500px", margin: "auto" }}
                              bordered
                            >
                              {onboardingdata.candidate_progress.map(
                                (number, index) => (
                                  <Descriptions.Item
                                    key={index}
                                    label={number.id}
                                    span={3}
                                  >
                                    {number.status === 7 ||
                                      number.status === 1 ||
                                      number.status === 21 ||
                                      number.status === 3 ||
                                      number.status === 25 ||
                                      number.status === 26 ||
                                      number.status === 13 ? (
                                      <CheckCircleFilled
                                        style={{
                                          fontSize: "24px",
                                          color: "#04980a",
                                        }}
                                      />
                                    ) : null}
                                    {number.status === 5 ||
                                      number.status === 0 ||
                                      number.status === 9 ||
                                      number.status === null ||
                                      number.status === 4 ||
                                      number.status === 23 ||
                                      number.status === 20 ||
                                      number.status === 12 ? (
                                      <ClockCircleFilled
                                        style={{
                                          fontSize: "24px",
                                          color: "#e99c08",
                                        }}
                                      />
                                    ) : null}
                                    {number.status === 6 ||
                                      number.status === 22 ||
                                      number.status === 24 ||
                                      number.status === 16 ||
                                      number.status === 17 ||
                                      number.status === 18 ||
                                      number.status === 2 ||
                                      number.status === 19 ? (
                                      <CloseCircleFilled
                                        style={{
                                          fontSize: "24px",
                                          color: "red",
                                        }}
                                      />
                                    ) : null}

                                    <span style={{ verticalAlign: "top" }}>
                                      {" "}
                                      {number.value}
                                    </span>
                                  </Descriptions.Item>
                                )
                              )}

                              {onboardingdata?.rad_id === null && (
                                <Descriptions.Item
                                  label="Enter RAD ID"
                                  span={3}
                                >
                                  <Input.Group compact>
                                    <Input
                                      maxLength={9}
                                      status={isRADIDError ? "error" : null}
                                      onChange={(e) => {
                                        const reg = new RegExp(
                                          "^[a-zA-Z0-9]{0,9}$"
                                        );
                                        if (e.target.value.match(reg)) {
                                          setRADIDError(false);
                                          setradid(e.target.value);
                                        } else {
                                          setRADIDError(true);
                                        }
                                      }}
                                      style={{
                                        width: "calc(100% - 100px)",
                                      }}
                                      defaultValue={
                                        onboardingdata?.rad_id ?? ""
                                      }
                                    />
                                    <Button
                                      type="primary"
                                      // disabled={radid !== "" ? false : true}
                                      disabled={isRADIDError}
                                      onClick={radidupdate}
                                    >
                                      Update
                                    </Button>
                                  </Input.Group>
                                </Descriptions.Item>
                              )}
                            </Descriptions>
                          </Col>

                          <Col className="mt-4" span={24}>
                            {onboardingdata?.status >= 2 ? (
                              onboardingdata?.status === 22 ||
                                onboardingdata?.status === 13 ? null : (
                                <>
                                  <div className="row justify-content-md-center">
                                    <div class="col-md-auto">
                                      <div className="text-center">
                                        <Button
                                          style={{ marginBottom: 10 }}
                                          onClick={() => getdata()}
                                          type="primary"
                                        >
                                          Refresh
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                  {onboardingdata?.status === 4 ||
                                    onboardingdata?.status === 8 ||
                                    onboardingdata?.status === 9 ||
                                    onboardingdata?.expired_at_status === 4 ||
                                    onboardingdata?.expired_at_status === 8 ||
                                    onboardingdata?.expired_at_status === 9 ? (
                                    <>
                                      <div className="row justify-content-md-center mt-2">
                                        <div class="col-md-auto">
                                          <Descriptions bordered>
                                            <Descriptions.Item label="Email :">
                                              {onboardingdata
                                                ?.candidate_progress[3].email ??
                                                ""}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Password :">
                                              {onboardingdata
                                                ?.candidate_progress[3]
                                                .password ?? ""}
                                            </Descriptions.Item>
                                            <Descriptions.Item label=" ETC Code :">
                                              {onboardingdata
                                                ?.candidate_progress[3]
                                                .telgoo_id ?? ""}
                                            </Descriptions.Item>
                                          </Descriptions>
                                        </div>
                                      </div>
                                      <div className="row justify-content-md-center mt-4">
                                        <div class="col-md-auto">
                                          <div className="text-center">
                                            <a
                                              target="_blank"
                                              rel="noreferrer"
                                              href={
                                                onboardingdata?.talent_lms_url ??
                                                ""
                                              }
                                            >
                                              <Button
                                                style={{ marginBottom: 10 }}
                                                type="primary"
                                              >
                                                Excess Telecom Training
                                              </Button>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : null}
                                </>
                              )
                            ) : null}

                            {onboardingdata?.status === 13 && (
                              <div class="col-md-auto col-sm-6">
                                <div className="text-center">
                                  <a
                                    rel="noreferrer"
                                    href={
                                      onboardingdata?.agent_portal_url ?? ""
                                    }
                                    target="_blank"
                                  >
                                    <Button type="primary">
                                      Agent Application Link
                                    </Button>
                                  </a>
                                </div>
                              </div>
                            )}
                          </Col>
                        </Row>
                        {onboardingdata?.status === 13 ? (
                          <Row gutter={24}>
                            <Col xs={24} lg={24} xl={24}>
                              <Title
                                className="text-center text-primary mt-3"
                                level={4}
                              >
                                Congratulations and welcome aboard.
                              </Title>
                              <Typography className="text-center">
                                <Paragraph>
                                  Please click above link to access application
                                </Paragraph>
                              </Typography>
                            </Col>
                          </Row>
                        ) : null}
                        {onboardingdata?.status === 14 ||
                          onboardingdata?.status === 15 ||
                          onboardingdata?.status === 16 ||
                          onboardingdata?.status === 17 ||
                          onboardingdata?.status === 18 ||
                          onboardingdata?.status === 19 ||
                          onboardingdata?.expired_at_status === 14 ||
                          onboardingdata?.expired_at_status === 15 ||
                          onboardingdata?.expired_at_status === 16 ||
                          onboardingdata?.expired_at_status === 17 ||
                          onboardingdata?.expired_at_status === 18 ||
                          onboardingdata?.expired_at_status === 19 ? (
                          <Form
                            form={form}
                            name="basic"
                            layout="vertical"
                            onFinish={radfailupdate}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                          >
                            <Row gutter={24}>
                              <Col xs={24} lg={24} xl={24}>
                                <Form.Item
                                  label="RAD ID"
                                  name="update_rad_id"
                                  initialValue={onboardingdata?.rad_id}
                                  rules={[
                                    {
                                      // pattern: new RegExp("^[a-zA-Z0-9]{0,9}$"),
                                      required: true,
                                      message: "Please input RAD ID",
                                    },
                                    {
                                      pattern: /^[A-Za-z0-9]+$/,
                                      message: "Please enter a valid RAD ID",
                                    },
                                  ]}
                                >
                                  <Input
                                    maxLength={9}
                                    placeholder="Enter RAD ID"
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} lg={12} xl={12}>
                                <Form.Item
                                  label="Retailer / Agent Agreement"
                                  name="retaileragreement1"
                                  rules={[
                                    {
                                      required: false,
                                      message: "Please upload document",
                                    },
                                  ]}
                                >
                                  <FileUploadDoc
                                    fileList={fileList4}
                                    setFileList={setFileList4}
                                    existingFile={retailerurl1}
                                    setExistingFile={setretailerurl1}
                                    form={form}
                                    name="retaileragreement1"
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} lg={12} xl={12}>
                                <Form.Item
                                  label="Supporting Documents"
                                  name="supportdocuments1"
                                  rules={[
                                    {
                                      required: false,
                                      message: "Please upload document",
                                    },
                                  ]}
                                >
                                  <FileUploadDoc
                                    fileList={fileList5}
                                    setFileList={setFileList5}
                                    existingFile={supporturl1}
                                    setExistingFile={setsupporturl1}
                                    form={form}
                                    name="supportdocuments1"
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={24}>
                                <div className="float-left">
                                  <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={loading}
                                  >
                                    Submit
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        ) : null}
                      </>
                    ) : null}
                    {onboardingdata?.status === 24 ||
                      onboardingdata?.expired_at_status === 24 ? (
                      <div className="container-fluid">
                        <Alert
                          message="Verification failed due to below reasons"
                          description={onboardingdata.rad_pii_issue_names.map(
                            (item) => {
                              return (
                                <div>
                                  <Typography.Text>{item.name}</Typography.Text>
                                </div>
                              );
                            }
                          )}
                          type="error"
                          showIcon
                        />
                        <h5 className="mb-0 mt-3">Candidate Details</h5>
                        <hr />
                        <Form
                          form={form}
                          name="candidateprofile"
                          layout="vertical"
                          onFinish={UpdateProfileFinish}
                          onFinishFailed={onFinishFailed}
                          autoComplete="off"
                        >
                          <Row gutter={24}>
                            <Col xs={24} lg={8} xl={8}>
                              <Form.Item
                                label="First Name"
                                name="first_name"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input first name",
                                  },
                                ]}
                              >
                                <Input placeholder="Enter Firstname" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} lg={8} xl={8}>
                              <Form.Item
                                label="Middle Name (Optional)"
                                name="middle_name"
                                rules={[
                                  {
                                    required: false,
                                    message: "Please input middle name",
                                  },
                                ]}
                              >
                                <Input placeholder="Enter Middle name" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} lg={8} xl={8}>
                              <Form.Item
                                label="Last Name"
                                name="last_name"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input last name",
                                  },
                                ]}
                              >
                                <Input placeholder="Enter Lastname" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} lg={12} xl={12}>
                              <Form.Item
                                label="Email Address"
                                name="email"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input valid email!",
                                  },
                                ]}
                              >
                                <Input type="email" placeholder="Enter Email" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} lg={12} xl={12}>
                              <Form.Item
                                label="Phone Number"
                                name="mobile_number"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input Phone Number!",
                                  },
                                ]}
                              >
                                <Input disabled placeholder="Enter Mobile no" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} lg={12} xl={12}>
                              <Form.Item
                                label="Date of birth"
                                name="dob"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input valid date!",
                                  },
                                ]}
                                extra="Must be above 18 years old"
                              >
                                <DatePicker
                                  defaultValue={moment(
                                    "2015-01-01",
                                    "MM-DD-YYYY"
                                  )}
                                  style={{ width: "100%" }}
                                  format={"MM-DD-YYYY"}
                                  placeholder="Date of Birth"
                                  disabledDate={(current) =>
                                    current.isAfter(
                                      moment().subtract(18, "year")
                                    )
                                  }
                                  showToday={false}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} lg={12} xl={12}>
                              <Form.Item
                                label="SSN Number"
                                name="ssn_number"
                                rules={[
                                  {
                                    required: true,
                                    pattern: new RegExp(
                                      /^(?!(000|666|9))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$|^(?!(000|666|9))\d{3}(?!00)\d{2}(?!0000)\d{4}$/
                                    ),
                                    message: "Please input valid SSN Number!",
                                  },
                                ]}
                              >
                                <Input placeholder="Enter SSN number" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} lg={12} xl={12}>
                              <Form.Item
                                label="RAD ID"
                                name="rad_id"
                                rules={[
                                  {
                                    // pattern: new RegExp("^[a-zA-Z0-9]{0,9}$"),
                                    required: true,
                                    message: "Please input RAD ID",
                                  },
                                  {
                                    pattern: /^[A-Za-z0-9]+$/,
                                    message: "Please enter a valid RAD ID",
                                  },
                                ]}
                              >
                                <Input
                                  maxLength={9}
                                  placeholder="Enter RAD ID"
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} lg={12} xl={12}>
                              <Form.Item
                                label="Gender"
                                name="gender_id"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select your gender",
                                  },
                                ]}
                              >
                                <Radio.Group>
                                  <Radio value={1}>Male</Radio>
                                  <Radio value={2}>Female</Radio>
                                </Radio.Group>
                              </Form.Item>
                            </Col>
                            <Col xs={24} lg={12} xl={12}>
                              <Form.Item
                                label="Shirt Size"
                                name="shirt_size"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input Shirt Size",
                                  },
                                ]}
                              >
                                <Select
                                  style={{
                                    width: "100%",
                                  }}
                                  placeholder="Select shirt size"
                                  options={[
                                    {
                                      value: "XS",
                                      label: "XS",
                                    },
                                    {
                                      value: "S",
                                      label: "S",
                                    },
                                    {
                                      value: "M",
                                      label: "M",
                                    },
                                    {
                                      value: "L",
                                      label: "L",
                                    },
                                    {
                                      value: "XL",
                                      label: "XL",
                                    },
                                    {
                                      value: "XXL",
                                      label: "XXL",
                                    },
                                    {
                                      value: "XXXL",
                                      label: "XXXL",
                                    },
                                  ]}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <h5 className="mb-0">Address Details</h5>
                          <hr />

                          <Row gutter={24}>
                            <Col xs={24} lg={8} xl={8}>
                              <Form.Item
                                label="Street Address1"
                                name="street_address1"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input valid address!",
                                  },
                                ]}
                              >
                                <Input placeholder="Enter Address 1" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} lg={8} xl={8}>
                              <Form.Item
                                label="Street Address2"
                                name="street_address2"
                                rules={[
                                  {
                                    required: false,
                                    message: "Please input valid address!",
                                  },
                                ]}
                              >
                                <Input placeholder="Enter Address 2" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} lg={8} xl={8}>
                              <Form.Item
                                label="City"
                                name="city"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input city!",
                                  },
                                ]}
                              >
                                <Input placeholder="Enter City" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} lg={8} xl={8}>
                              <Form.Item
                                label="State"
                                name="state"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input State!",
                                  },
                                ]}
                              >
                                <Select
                                  showSearch
                                  style={{
                                    width: "100%",
                                  }}
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    (option?.label ?? "")
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? "")
                                      .toLowerCase()
                                      .localeCompare(
                                        (optionB?.label ?? "").toLowerCase()
                                      )
                                  }
                                  placeholder="Select State"
                                  options={(shortstate || []).map((d) => ({
                                    value: d.short_name,
                                    label: d.name,
                                  }))}
                                ></Select>
                              </Form.Item>
                            </Col>
                            <Col xs={24} lg={8} xl={8}>
                              <Form.Item
                                label="Zipcode"
                                name="zip"
                                rules={[
                                  {
                                    required: true,
                                    pattern: new RegExp(/^[0-9]+$/),
                                    message: "Please input valid Zipcode!",
                                  },
                                ]}
                              >
                                <Input
                                  maxLength={5}
                                  placeholder="Enter Zipcode"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={24}>
                            <Col xs={24} lg={12} xl={12}>
                              <Form.Item
                                label="Government Picture ID "
                                name="government_document"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please upload valid document",
                                  },
                                ]}
                              >
                                <FileGovUpload
                                  fileList={fileList}
                                  setFileList={setFileList}
                                  existingFile={governmenturl}
                                  setExistingFile={setgovernmenturl}
                                  form={form}
                                  name="government_document"
                                />
                              </Form.Item>
                            </Col>
                            {source === 1 && (
                            <Col xs={24} lg={12} xl={12}>
                              <Form.Item
                                label="Profile Picture"
                                name="photo_copy"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please upload your picture",
                                  },
                                ]}
                              >
                                <FileUpload
                                  fileList={fileList1}
                                  setFileList={setFileList1}
                                  existingFile={photourl}
                                  setExistingFile={setphotourl}
                                  form={form}
                                  name="photo_copy"
                                />
                              </Form.Item>
                            </Col>)}
                            {source !== 1 ? (
                          <Col xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Profile Picture 2"
                            name="photo_copy_2"
                            rules={[
                              {
                                required: true,
                                message: "Please upload your new profile image",
                              },
                            ]}
                          >
                            <FileUpload2
                              fileList={fileList6}
                              setFileList={setFileList6}
                              existingFile={photourl2}
                              setExistingFile={setphotourl2}
                              form={form}
                              name="photo_copy_2"
                            />
                          </Form.Item>
                        </Col>
                        ):null}
                            <Col xs={24} lg={12} xl={12}>
                              <Form.Item
                                label="Retailer / Agent Agreement"
                                name="retaileragreement"
                                rules={[
                                  {
                                    required: false,
                                    message: "Please upload document",
                                  },
                                ]}
                              >
                                <FileUploadDoc
                                  fileList={fileList2}
                                  setFileList={setFileList2}
                                  existingFile={retailerurl}
                                  setExistingFile={setretailerurl}
                                  form={form}
                                  name="retaileragreement"
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} lg={12} xl={12}>
                              <Form.Item
                                label="Supporting Documents"
                                name="supportdocuments"
                                rules={[
                                  {
                                    required: false,
                                    message: "Please upload document",
                                  },
                                ]}
                              >
                                <FileUploadDoc
                                  fileList={fileList3}
                                  setFileList={setFileList3}
                                  existingFile={supporturl}
                                  setExistingFile={setsupporturl}
                                  form={form}
                                  name="supportdocuments"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <div className="float-left">
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  loading={loading}
                                >
                                  Submit
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          </Content>
        </Spin>

        <Footer
          style={{
            textAlign: "center",
          }}
        >
          © {currentYear} Excess Telecom Wireless | Wireless and Approved Affordable
          Connectivity Program Provider | Version 6.4.0
        </Footer>
      </Layout>
    </>
  );
}
